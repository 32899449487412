import { NeuronProvider as Provider, type SPAConfig } from "@sphtech/neuron";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  configName: string;
};

export default function NeuronProvider({
  children,
  configName,
}: Props): ReactNode {
  // Disable Neuron in CUE preview
  // https://sph.atlassian.net/browse/DMG-3774
  if (
    typeof window !== "undefined" &&
    window.location.search.includes("preview_token=")
  ) {
    return children;
  }

  const neuronConfig: SPAConfig = {
    sessionApiUrl: import.meta.env.VITE_NEURON_API_URL,
    sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN,
  };

  return (
    <Provider config={neuronConfig} configName={configName}>
      {children}
    </Provider>
  );
}
