import cx from "classnames";
import { ComponentProps, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

import styles from "./AiSummaryLightbox.module.css";
import AiSummaryStarRating from "./AiSummaryStarRating";
import CloseIcon from "../../Element/CloseIcon/CloseIcon";
import { Body } from "../../Element/Typography";
import { SubHeading2 } from "../../Element/Typography/Heading/Heading";
import * as Ads from "../../Utility/Ads/Ads";

type AiSummaryLightboxProps = {
  isOpen: boolean;
  toggle: () => void;
  data: {
    id: string;
    title: string;
    path: string;
    items?: string[];
  };
  gaEventTracker?: () => void;
  submitStarRating?: ComponentProps<
    typeof AiSummaryStarRating
  >["submitStarRating"];
};

export default function AiSummaryLightbox({
  isOpen,
  toggle,
  data,
  gaEventTracker,
  submitStarRating,
}: AiSummaryLightboxProps): ReactNode {
  useEffect(() => {
    if (!isOpen) return;

    if (gaEventTracker) {
      gaEventTracker();
    }
  }, [isOpen, gaEventTracker]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        if (isOpen) {
          toggle();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isOpen, toggle]);

  return createPortal(
    <div
      className={cx(styles.lightbox, isOpen && styles.lightboxOpen)}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          toggle();
        }
      }}
    >
      <div className={styles.lightboxContainer}>
        <div className={styles.lightboxContent}>
          <div className={styles.lightboxHeader}>
            <button onClick={toggle} className={styles.closeButton}>
              <CloseIcon size={20} />
            </button>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.scrollableContent}>
              <div className={styles.summary}>
                {data.title && <SubHeading2>{data.title}</SubHeading2>}

                <ul className={styles.summaryList}>
                  {!!data.items &&
                    data.items.map((item, index) => {
                      return (
                        <li key={index} className={styles.listItem}>
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className={styles.rating}>
                <Body Element="p">Was this AI summary helpful?</Body>
                <AiSummaryStarRating
                  submitStarRating={submitStarRating}
                  storyId={data.id}
                />
              </div>
            </div>
          </div>
          <div className={styles.advertisement}>
            <Ads.AdsAiSummary />
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}
