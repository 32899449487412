/**
 * For GA4 Tracking
 */

import { configSite, configTrackingCode } from "@pub/config";

import {
  GA4_REF_CODE_CONTENTBLOCK,
  GA4_REF_CODE_CONTENTBLOCK_A,
  GA4_REF_CODE_CONTENTBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_C,
  GA4_REF_CODE_CONTENTBLOCK_D,
  GA4_REF_CODE_CONTENTBLOCK_E,
  GA4_REF_CODE_CONTENTBLOCK_F,
  GA4_REF_CODE_CONTENTBLOCK_G,
  GA4_REF_CODE_SHOPPING_WIDGET,
  GA4_REF_CODE_TRENDING,
  GA4_REF_CODE_VIDEOGALLERY,
  GA5_REF_CODE_CONTENTHUB,
  GA5_REF_CODE_CONTENTHUB_A,
  GA5_REF_CODE_CONTENTHUB_B,
} from "./ref-code";

export const buildUrlWithGARefCode = (
  url: string,
  refCode?: string,
  skipSameSiteCheck: boolean = false, // To allow outside urls. For ex: subscribe links
) => {
  if (
    !refCode ||
    (!url.startsWith("/") &&
      !skipSameSiteCheck &&
      !url.startsWith(configSite.address))
  ) {
    return url;
  }
  const [base, querystring] = url.split("?");
  const querystringParts = querystring
    ? querystring.split("&").filter((urlPart) => {
        // remove existing ref, if any
        return !urlPart.startsWith("ref=");
      })
    : [];
  return `${base}?${querystringParts
    .concat(`ref=${encodeURIComponent(refCode)}`)
    .join("&")}`;
};

export const getContentBlockRefCode = (section: string) => {
  const blockRefCodes = [
    { section: "trending", code: GA4_REF_CODE_TRENDING },
    { section: "topic", code: GA4_REF_CODE_CONTENTBLOCK_B },
    { section: "socialvideos", code: "" },
    { section: "videoplaylist", code: GA4_REF_CODE_VIDEOGALLERY },
    { section: "contentblock", code: GA4_REF_CODE_CONTENTBLOCK },
    { section: "block-f", code: GA4_REF_CODE_CONTENTBLOCK_F },
    { section: "block-g", code: GA4_REF_CODE_CONTENTBLOCK_G },
    { section: "block-a", code: GA4_REF_CODE_CONTENTBLOCK_A },
    { section: "block-b", code: GA4_REF_CODE_CONTENTBLOCK_B },
    { section: "block-c", code: GA4_REF_CODE_CONTENTBLOCK_C },
    { section: "block-d", code: GA4_REF_CODE_CONTENTBLOCK_D },
    { section: "block-e", code: GA4_REF_CODE_CONTENTBLOCK_E },
    { section: "contenthub", code: GA5_REF_CODE_CONTENTHUB },
    { section: "contenthub-a", code: GA5_REF_CODE_CONTENTHUB_A },
    { section: "contenthub-b", code: GA5_REF_CODE_CONTENTHUB_B },
    { section: "column-side-scroll", code: GA4_REF_CODE_SHOPPING_WIDGET },
    ...(configTrackingCode.customSection?.map((section) => ({
      section,
      code: section,
    })) || []),
  ];

  return blockRefCodes.find((elem) => elem.section === section)?.code || "";
};
