import { Deal } from "@app/types/ServerCustomContext";
import amazonPricingDataLoader from "@app/utils/amazonPricingDataloader";
import { GA4_REF_CODE_AP_WIDGET } from "@app/utils/ga/ref-code";
import { buildUrlWithGARefCode } from "@app/utils/ga/tracking";
import { formatDiscount, formatPrice } from "@app/utils/product";
import { configAffiliatesProduct } from "@pub/config";
import placeholders from "@pub/placeholders";
import { AffiliatesProductCarousell } from "@sphtech/dmg-design-system";
import { ReactNode } from "react";
import useSWR from "swr";

type Props = {
  data: {
    items: Deal[];
    itemHeight?: number;
    itemWidth?: number;
    noOfItemsInFrame?: number;
    navIcon?: {
      next: ReactNode;
      prev: ReactNode;
    };
  };
};

export default function ProductDeals({ data }: Props): ReactNode {
  const { items, itemHeight, itemWidth, noOfItemsInFrame } = data;

  const dyanmicPricing = items
    .map((item) => item.deals?.additionalFields?.dynamicPricing?.value)
    .filter(Boolean);

  const { data: amazonAffiatesData, isLoading } = useSWR(
    dyanmicPricing,
    (keys) => {
      return amazonPricingDataLoader
        .loadMany(keys)
        .then((data) =>
          data.map((data) => (data instanceof Error ? null : data)),
        );
    },
  );

  return (
    items.length > 0 && (
      <AffiliatesProductCarousell
        cta={{
          variant: configAffiliatesProduct.cta?.variant,
          buttonIcon: "",
          label: "SHOP NOW",
        }}
        itemHeight={itemHeight || 400}
        itemWidth={itemWidth || 190}
        items={items.map((item) => {
          const additionalFields = item.deals?.additionalFields;

          const dynamicPricing = additionalFields?.dynamicPricing?.value;

          const extractAmazonData =
            (!isLoading &&
              dynamicPricing &&
              amazonAffiatesData?.find(
                (item) => item && item.asin === dynamicPricing,
              )) ||
            undefined;

          const crop = item.image?.crops.original || placeholders.portrait;
          const originalPrice = formatPrice(
            extractAmazonData?.originalPrice || additionalFields?.price?.value,
          );
          const discountPrice = formatPrice(
            extractAmazonData?.discountPrice ||
              additionalFields?.discountedPrice?.value,
          );

          return {
            discountInPercentage:
              formatDiscount(
                extractAmazonData?.discountPercentage ||
                  additionalFields?.discount?.value,
                true,
                false,
              ) || "",
            discountPrice: discountPrice || "",
            discountPriceLabel: discountPrice ? "Now" : "",
            image: { ...crop },
            link: buildUrlWithGARefCode(
              additionalFields?.destinationUrl?.value || "",
              GA4_REF_CODE_AP_WIDGET,
            ),
            originalPrice: originalPrice || "",
            originalPriceLabel: originalPrice ? "Original Price: " : "",
            title: item.title,
            dynamicPricing: additionalFields?.dynamicPricing?.value,
          };
        })}
        noOfItemsInFrame={noOfItemsInFrame || 4}
      />
    )
  );
}
