import { ReactElement } from "react";
import cx from "classnames";
import * as Typography from "../../Element/Typography";
import ResponsiveImage from "../../Element/ResponsiveImage";

import styles from "./latestarticle.module.css";
import { placeholderCrops } from "../../Utility/placeholder";
import BreadCrumbs from "../../Element/BreadCrumb";
import { BaseTeaserData, ImageCrop } from "../../Utility/dataTypes";
import { SummaryItemSmall } from "../../Block/SummaryItem/SummaryItem";
import { useCustomClassContext } from "../../../hooks";

type Props = BaseTeaserData & {
  description?: string;
  dateAfter?: boolean;
  dateFormat?: string;
  /** The mode determines which image crop is used on desktop */
  mode: "portrait" | "landscape" | "original" | "square";
  /** The mode determines which image crop is used on mobile */
  mobileMode?: "portrait" | "original" | "landscape";
  imageSize?: "big" | "small";
  portraitImageWidth?: number;
  landscapeImageWidth?: number;
  placeholders?: {
    landscape: ImageCrop;
    portrait: ImageCrop;
    square: ImageCrop;
  };
  descriptionInTitle?: boolean;
  skipImage?: boolean;
  displayPublishDate?: boolean;
  displayPlayIcon?: boolean;
  CustomHeadingElement?: React.ComponentProps<
    typeof SummaryItemSmall
  >["CustomHeadingElement"];
  mobileUpAspectRatio?: number;
  aspectRatio?: number;
};

const { Body, DateTime } = Typography;

/**
 * Description: LatestArticle module shows image on top and summaryItem in the bottom.
 */
export default function LatestArticle({
  id,
  path,
  image,
  title,
  sections,
  description,
  publishDate,
  dateFormat = "dd mname yyyy",
  dateAfter,
  mode = "portrait",
  mobileMode = "original",
  imageSize = "big",
  portraitImageWidth = 300,
  landscapeImageWidth = 630,
  placeholders = placeholderCrops,
  descriptionInTitle = false,
  skipImage,
  displayPublishDate = true,
  displayPlayIcon,
  CustomHeadingElement,
  mobileUpAspectRatio,
  aspectRatio,
}: Props): ReactElement {
  const customClass = useCustomClassContext();
  const dateElement = displayPublishDate && !dateAfter && publishDate && (
    <DateTime date={new Date(publishDate)} format={dateFormat} />
  );

  const modeCrop = image?.crops[mode];
  const originalCrop = image?.crops.original;
  const mobileCrop = image?.crops[mobileMode];

  // Needs to respect mobile mode when selecting placeholder if mobileMode is NOT original
  const placeHolderImageMobile =
    mobileMode !== "original" && placeholders[mobileMode];
  const placeHolderImage =
    mode === "original" ? placeholders.portrait : placeholders[mode];

  const mobileImage =
    mobileCrop || modeCrop || placeHolderImageMobile || placeHolderImage;
  const mUpImage = modeCrop || placeHolderImage;

  if (!originalCrop || !modeCrop) {
    console.warn(`Missing Teaser Image Crops:`, {
      storyCueId: id,
      availableCrops: Object.keys(image?.crops || {}).join(" ") || "none",
      requested: [mode, "original"].join(" "),
      path,
    });
  }

  return (
    <div
      className={cx(
        styles.latestArticle,
        styles[mode],
        styles[imageSize],
        customClass?.latestArticle?.container,
      )}
    >
      {!skipImage && (
        <a href={path} className={styles.imageContainer}>
          <ResponsiveImage
            {...mobileImage}
            displayWidth={280}
            loading="lazy"
            fillAspectRatio={aspectRatio}
            mobileUp={{
              ...mUpImage,
              displayWidth:
                mode === "portrait" ? portraitImageWidth : landscapeImageWidth,
              fill: "cover",
              fillPosition: mode === "portrait" ? "center top" : undefined,
              fillAspectRatio: mobileUpAspectRatio,
            }}
            errorSrc={placeHolderImage.src}
          />

          {displayPlayIcon && (
            <svg
              className={styles.playIcon}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <circle cx="14.5106" cy="14.8861" r="14.0545" fill="black" />
              <path
                d="M21.0234 14.8861L11.2538 20.5266L11.2538 9.24563L21.0234 14.8861Z"
                fill="white"
              />
            </svg>
          )}
        </a>
      )}

      <div
        className={cx(
          styles.summaryContainer,
          styles[mode],
          mode === "landscape" && customClass?.latestArticle?.landscape,
        )}
      >
        <SummaryItemSmall
          title={
            descriptionInTitle && description
              ? [
                  `${title} `,
                  <Body key="description" Element="span">
                    {description}
                  </Body>,
                ]
              : title
          }
          url={path}
          beforeTitle={
            <BreadCrumbs sections={sections} dateElement={dateElement} />
          }
          afterTitle={
            !descriptionInTitle && description && <Body>{description}</Body>
          }
          publishedDate={dateAfter ? publishDate : undefined}
          dateFormat={dateFormat}
          CustomHeadingElement={CustomHeadingElement}
        />
      </div>
    </div>
  );
}
